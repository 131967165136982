import React, {FC, useContext, useEffect} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import { useLatestArticles } from "../../../common/hooks/useArticles";
import { Article } from "../../../common/types/articles";
import { Link, useNavigate } from "react-router-dom";
import { useHomeData } from "../../../common/hooks/useHomeData";
import AppContext from "../../../common/context/app-context";
import { Popup } from "../../../common/components/AuthModal";
import { GamePreview } from "../../../common/components/GamePreview";
import { ShowOffYourDogs } from "../../../common/types/home";
import {useTrackGoogleAnalyticsEventPages} from "../../../common/hooks/useTrackGoogleAnalyticsEvent";

const HomePage: FC = () => {
  const { data: latestArticles } = useLatestArticles();
  const navigate = useNavigate();
  const { me: currentUser, setShowAuthPopup } = useContext(AppContext);
  const { data: homeData } = useHomeData();

  useEffect(() => {
    useTrackGoogleAnalyticsEventPages()
  }, []);

  const loginCheckHandler = (url: string) => {
    if (!currentUser) {
      setShowAuthPopup({ authStep: Popup.Login });
    } else {
      navigate(url);
    }
  };

  const getFeaturedArticleElement = (): JSX.Element | undefined => {
    const featured = homeData?.data.articles.find((a) => a.type === "main");
    return (
      <Link to={`/article/${featured?.slug}`}>
        <div className="flex flex-col">
          <div className="flex flex-col">
            <div className="article-media article-media--lg w-100 mb-6 sm-mb-2 no-scroll">
              <img
                className="img img--full img--contain br"
                src={featured?.thumbnailUrl}
                alt=""
              />
            </div>
            <div className="flex flex-col justify-start align-start">
              <span className="fs-xl lh-xl sm-fs-md sm-lh-md fw-700 c-primary  sm-mb-1">
                {featured?.subTopicSelections
                  ?.map((e) => e.subTopic.name)
                  .join(", ")}
              </span>
              <span className="fs-h-4 lh-h-4 sm-fs-h-6 sm-lh-h-5 xs-fs-h-7 xs-lh-h-7 fw-700 c-text-black mb-2 sm-mb-1">
                {featured?.title}
              </span>
              <span className="fs-lg lh-xl sm-fs-sm sm-lh-md fw-400 c-text-4">
                {featured?.excerpt}
              </span>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  return (
      <div className="flex flex-col justify-center align-stretch bg-white">
        {/*
        <div className="flex py-16 bg-primary-light-4 px-6 xs-py-0 xs-px-0">
          <div className="container">
            <div className="landing-intro-holder flex flex-col justify-start align-stretch xs-py-4 relative">
              <div className="landing-intro-holder-title">
                <h2 className="mt-0 mb-4 xs-mb-6 xs-px-6 xs-fs-h-7 xs-lh-h-7 xs-text-center text-uppercase fw-500 c-text-1 hidden xs-block">
                  Think you
                  <br className="xs-hidden" />
                  &nbsp; <span className="fw-700 c-text-2">know dogs?</span>
                </h2>
              </div>
              <div className="landing-intro-slider-wrap">
                <Swiper
                  className="landing-intro-slider"
                  spaceBetween={72}
                  slidesPerView={2}
                  modules={[Pagination]}
                  pagination={{
                    clickable: true,
                    el: ".landing-intro-slider-pagination",
                    type: "bullets",
                    bulletClass: "landing-intro-slider-bullet",
                    bulletActiveClass: "landing-intro-slider-bullet--active",
                  }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 32,
                    },
                    1280: {
                      slidesPerView: 2,
                      spaceBetween: 72,
                    },
                  }}
                >
                  <SwiperSlide className="xs-px-6">
                    <div className="landing-intro--highlight-wrap">
                      <div className="landing-intro--highlight">
                        <img
                          className="img img--full img--contain xs-hidden"
                          src="/images/dog-highlight.webp"
                          alt=""
                        />
                        <img
                          className="img img--full img--contain hidden xs-block"
                          src="/images/dog-highlight-reverse.webp"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="landing-intro-container flex flex-col justify-start align-stretch xs-px-5 relative no-scroll">
                      <div className="landing-intro-wrap flex justify-start align-start xs-justify-center col-gap-6 no-scroll">
                        <div className="landing-intro-col xs-hidden">
                          <h3 className="mt-0 mb-4 fs-h-5 lh-h-5 sm-fs-h-7 sm-lh-h-7 fw-700 c-text-black">
                            Get in the know.
                            <br />
                            <span className="c-primary">
                              Get in on the fun.
                            </span>
                          </h3>
                          <p className="my-0 c-text-4 fs-sm lh-sm">
                            Duis aute irure dolor in reprehenderit in voluptate
                            velit
                          </p>
                        </div>

                        <div
                          className="landing-intro-media-wrap flex flex-col justify-start align-stretch grow-1 shrink-0 sm-shrink-1 h-100 pb-2 xs-px-2"
                        >
                          <div className="landing-intro-media score--media score--media-home flex flex-col xs-w-100 w-100 br text-center align-center jusitfy-center  pb-13  border-primary--lg xs-mx-auto">
                            <div
                              className={`score-card flex flex-wrap br--xxs justify-center`}
                            >
                              <div
                                className={`relative flex flex-col w-100`}
                              >
                                <GamePreview/>
                              </div>
                            </div>
                          </div>
                          <div className="flex w-100 mt-6">
                            <div
                              className="btn btn--lg btn--primary sm-btn--sm xs-btn--lg w-100"
                              onClick={() =>
                                navigate("game")
                              }
                            >
                              Play today's game
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="xs-px-6">
                    {!!homeData?.data.articles &&
                      homeData?.data.articles
                        .filter((a: Article) => a.type === "mainFeatured")
                        .map((a) => (
                          <Link to={`/article/${a.id}`} key={a.id}>
                            <div
                              className="landing-intro-post flex flex-col justify-start align-stretch "
                              key={a.title}
                            >
                              <span className="mt-0 mb-8 xs-mb-6 fs-h-1 lh-1 sm-fs-h-2 sm-fs-h-4 sm-lh-h-4 xs-text-center text-uppercase fw-700 c-text-1 xs-hidden">
                                think you
                                <br />
                                <span className="fs-h-1 lh-1 sm-fs-h-2 sm-fs-h-4 sm-lh-h-4 xs-text-center text-uppercase fw-700 c-text-2 xs-hidden">
                                  know
                                </span>
                                &nbsp;
                                <span className="fs-h-1 lh-1 sm-fs-h-2 sm-fs-h-4 sm-lh-h-4 xs-text-center text-uppercase fw-700 c-text-2 xs-hidden">
                                  dogs?
                                </span>
                              </span>

                              <div className=" flex w-100 mb-6 xs-mb-4">
                                <img
                                  className="img img--full br  img--cover img--cover-center landing-intro-post--media"
                                  alt=""
                                  src={a.thumbnailUrl}
                                />
                              </div>

                              <span className="fs-xl lh-xl sm-fs-md sm-lh-md fw-700 c-primary  sm-mb-1">
                                {a?.subTopicSelections
                                  ?.map((e) => e.subTopic.name)
                                  .join(", ")}
                              </span>
                              <p className="block fs-h-7 lh-h-7  mb-1 fw-700 c-text-black">
                                {a.title}
                              </p>
                              <p className="xs-block hidden fs-sm lh-sm  fw-400 c-text-black">
                                {a.description}
                              </p>
                            </div>
                          </Link>
                        ))}
                  </SwiperSlide>
                </Swiper>
              </div>
              <div className="landing-intro-slider-pagination justify-center align-center hidden pt-3 col-gap-2 xs-flex pt-3"></div>
            </div>
          </div>
        </div>
*/}

        <div className="landing flex w-100 align-center xs-px-3 xs-py-0">
          <div className="container">
            <div className="flex landing-wrapper xs-flex-col w-100 align-stretch grow-1 xs-py-5">
              <div className="flex grow-1 w-100 xs-flex-col-reverse col-gap-12 sm-col-gap-10 xs-col-gap-0">
                <div className="landing--media justify-end xs-justify-center sm-justify-end xs-grow-1">
                  <div className="landing--media-img align-stretch justify-center">
                    <div
                      className="landing-game-preview justify-center align-center xs-justify-center xs-align-center flex pointer"
                      onClick={() => navigate("/game")}
                    >
                      <GamePreview />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col xs-align-center w-100 h-100 xs-h-auto xs-shrink-0">
                  <div className="landing--title flex flex-col xs-mb-2">
                  
                     <span className="hidden xs-block fs-h-1 lh-h-1 fw-500 sm-fs-h-2 sm-lh-h-2 xs-fs-h-6 xs-lh-h-6 xs-text-center xs-align-center xs-justify-center c-text-1 uppercase">
                     Flipped on Dogs {""}
                      <br className="xs-hidden" />
                      <span className="fs-h-1 lh-h-1 sm-fs-h-2 sm-lh-h-2 xs-fs-h-6 xs-lh-h-6 fw-700 c-text-2 uppercase">
                        daily game
                      </span>
                    </span>


                    <span className="xs-hidden fs-h-1 lh-h-1 fw-500 sm-fs-h-2 sm-lh-h-2 xs-fs-h-6 xs-lh-h-6 xs-text-center xs-align-center xs-justify-center c-text-1 uppercase">
                      learn to {""}
                      <br className="xs-hidden" />
                      <span className="fs-h-1 lh-h-1 sm-fs-h-2 sm-lh-h-2 xs-fs-h-6 xs-lh-h-6 fw-700 c-text-2 uppercase">
                        speak dog
                      </span>
                    </span>
                  </div>
                  <div className="flex justify-start text-left align-start xs-hidden">
                    <p className="fs-h-6 lh-h-5 sm-fs-h-8 sm-lh-xl c-text-black mt-5 sm-mt-2">
                      <span className="fw-700">Play a quick, fun game every day</span> to challenge your dog smarts.
                    </p>
                  </div>
                  <div className="landing--play-btn flex w-100 xs-mt-6 xs-mb-6 xs-hidden sm-mt-5 mt-10">
                    <div
                      className="btn btn--xl btn--primary sm-btn xs-btn--lg w-100"
                      onClick={() => navigate("game")}
                    >
                      PLAY NOW
                    </div>
                  </div> 
                </div>
              </div>
              <div className="landing--play-btn xs-shrink-0 w-100 xs-mt-3  hidden xs-flex xs-justify-center xs-align-center xs-mx-auto sm-mt-5 mt-10">
                <div
                  className="btn btn--xl btn--primary sm-btn xs-btn--lg w-100"
                  onClick={() => navigate("game")}
                >
                  PLAY NOW
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col xs-py-17 py-20 bg-primary-light-2 ">
          <h2 className="fs-h-2 lh-h-2 fw-700 c-text-2 text-uppercase text-center xs-pt-0 pt-20 mb-6 xs-fs-h-3 xs-lh-h-3 sm-fs-h-3 sm-lh-h-3">
            show off your dog
          </h2>

          <div className="relative container flex flex-col justify-start align-stretch col-gap-2 mb-6 mt-2">
            <Swiper
              className="landing-dogs-slider w-100"
              spaceBetween={8}
              slidesPerView={4}
              centerInsufficientSlides={true}
              loop={false}
              modules={[Navigation]}
              navigation={{
                prevEl: ".landing-dogs-slider-btn--prev",
                nextEl: ".landing-dogs-slider-btn--next",
              }}
              breakpoints={{
                320: {
                  loop: true,
                  slidesPerView: 1,
                  spaceBetween: 0,
                  centerInsufficientSlides: false,
                },
                768: {
                  loop: false,
                  slidesPerView: 4,
                  spaceBetween: 8,
                  centerInsufficientSlides: true,
                },
                1280: {
                  loop: false,
                  slidesPerView: 4,
                  spaceBetween: 8,
                  centerInsufficientSlides: true,
                },
              }}
            >
              {!!homeData?.data?.showOffYourDogs &&
                homeData.data.showOffYourDogs?.map((dog: ShowOffYourDogs) => (
                  <SwiperSlide key={dog.id}>
                    <div className="flex flex-col justify-start align-stretch">
                      <div className="flex w-100 ">
                        <img
                          src={dog.imgUrl}
                          className="img img--full img--cover swiper-media"
                          alt=""
                        />
                      </div>
                      <div className="flex justify-between align-center col-gap-2 bg-primary-light py-2 px-4 xs-px-6">
                        <div className="flex flex-col tex-start justify-start grow-1 shrink-0">
                          <span className="fs-h-7 lh-h-7 sm-fs-xl sm-lh-xl xs-fs-md xs-lh-md fw-700 c-text-black">
                            {dog.name}
                          </span>
                          <span className="fs-lg lh-lg sm-fs-md sm-lh-md xs-fs-sm xs-lh-sm c-text-1 fw-500">
                            {!!dog.description ? dog.description : <>&nbsp;</>}
                          </span>
                        </div>
                        <div className="flex flex-col justify-end align-end">
                          <span className="fs-lg lh-lg sm-fs-sm sm-lh-sm c-text-white fw-700 text-right">
                            {`${dog.city},`}
                          </span>
                          <span className="fs-lg lh-lg sm-fs-sm sm-lh-sm c-text-white fw-700 text-right">
                            {`${dog.state}`}
                          </span>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
            <button className="btn-reset landing-dogs-slider-btn landing-dogs-slider-btn--prev hidden xs-flex">
              <i className="flipicon flipicon--lg flipicon-left-circle">
                <span className="path1 c-text-1"></span>
                <span className="path2 c-primary-light"></span>
              </i>
            </button>
            <button className="btn-reset landing-dogs-slider-btn landing-dogs-slider-btn--next hidden xs-flex">
              <i className="flipicon flipicon--lg flipicon-right-circle">
                <span className="path1 c-text-1"></span>
                <span className="path2 c-primary-light"></span>
              </i>
            </button>
          </div>

          <div className="container flex flex-col justify-center align-center text-center mw-800 pb-10 xs-pb-20 xs-px-10">
            <span className="fs-lg lh-xl fw-400 c-text-4 mb-6 xs-fs-sm xs-lh-sm sm-fs-md sm-lh-sm">
              Get your dog featured in the game! You can even share your own
              videos or send in questions about behavior and training. Is your
              dog our next Pup Star?{" "}
              <a
                onClick={() => loginCheckHandler('submit-dog-step-1')}
                className="fs-lg lh-xl fw-400 c-text-4 mb-6 xs-fs-sm xs-lh-sm sm-fs-md sm-lh-sm underline"
              >
               Submit and find out.
              </a>
            </span>
            <div className="flex justify-center align-center xs-w-100">
              <Link
                to="submit-dog-step-1"
                state={{ showAuthPopup: true }}
                className="btn btn--primary xs-btn--lg xs-w-100"
              >
                submit my dog
              </Link>
            </div>
          </div>

          {/*
          <div className="container flex text-center col-gap-6 py-16 xs-flex-col xs-row-gap-10 xs-mb-12 pointer">
            <div
              onClick={(event) => {
                event.preventDefault();
                loginCheckHandler("/game");
              }}
              className="flex flex-col text-center justify-center align-center grow-1"
            >
              <i className="flipicon flipicon--xxl sm-flipicon--xxl flipicon-game c-primary"></i>
              <span className="text-uppercase fs-h-7 lh-h-7 sm-fs-xl sm-lh-xl fw-700 c-primary mt-6">
                game
              </span>
              <span className="c-text-4 fs-lg lh-lg sm-fs-sm sm-lh-sm fw-400 mt-2">
                Duis aute irure dolor in reprehenderit
              </span>
            </div>
            <div
              onClick={(event) => {
                event.preventDefault();
                loginCheckHandler("/scrap-book");
              }}
              className="flex flex-col text-center justify-center align-center grow-1 pointer"
            >
              <i className="flipicon flipicon--xxl sm-flipicon--xxl flipicon-scrapbook c-primary"></i>
              <span className="text-uppercase fs-h-7 lh-h-7 sm-fs-xl sm-lh-xl fw-700 c-primary mt-6">
                your scrapboook
              </span>
              <span className="c-text-4 fs-lg lh-lg sm-fs-sm sm-lh-sm fw-400 mt-2">
                Duis aute irure dolor in reprehenderit
              </span>
            </div>
            <Link
              to="/topics"
              className="flex flex-col text-center justify-center align-center grow-1"
            >
              <i className="flipicon flipicon--xxl sm-flipicon--xxl flipicon-topics c-primary"></i>
              <span className="text-uppercase fs-h-7 lh-h-7 sm-fs-xl sm-lh-xl fw-700 c-primary mt-6">
                topics
              </span>
              <span className="c-text-4 fs-lg lh-lg sm-fs-sm sm-lh-sm fw-400 mt-2">
                Duis aute irure dolor in reprehenderit
              </span>
            </Link>
          </div>
*/}
        </div>
        {!!homeData?.data.articles && (
          <>
            <div className="container flex flex-col w-100 justify-center align-center pt-20 xs-pt-0 xs-px-6">
              <div
                className="flex flex-row justify-center text-center align-center mb-20 xs-flex-col-reverse xs-mb-12
              "
              >
                <h2 className="text-uppercase fs-h-1 lh-h-1 sm-fs-h-3 sm-lh-h-3 xs-fs-h-3 xs-lh-h-3 fw-700 c-text-2">
                  today's topics
                </h2>
                <div className="flex topics-media">
                  <img
                    className="img img--full img--contain"
                    src="/images/dog.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-row w-100 col-gap-10 sm-col-gap-8 sm-px-6 xs-px-0 xs-flex-col">
                <div className="flex flex-col row-gap-20 sm-row-gap-10 xs-row-gap-12 topics-main">
                  {getFeaturedArticleElement()}
                  <div className="flex flex-col grow-1 justify-start ">
                    <div className="flex mb-10 sm-mb-5">
                      <span className="fs-h-4 lh-h-4 sm-fs-h-6 sm-lh-h-6 xs-fs-h-5 xs-lh-h-5 fw-700 c-text-2">
                        Latest
                      </span>
                    </div>
                    <div className="flex flex-row col-gap-6 xs-flex-col">
                      {!!homeData?.data.articles &&
                        homeData?.data.articles
                          ?.filter((item) => item.type === "latest")
                          .map((article: Article) => (
                            <Link
                              to={`/article/${article.slug}`}
                              key={article.id}
                            >
                              <div className="flex flex-col">
                                <div className="article-media article-media--sm mb-6 sm-mb-2">
                                  <img
                                    className="img img--full img--contain br"
                                    src={article.thumbnailUrl}
                                    alt={article.title}
                                  />
                                </div>
                                <div className="flex flex-col align-start justify-start xs-mt-2 xs-mb-6">
                                  <span className="fs-xl lh-xl sm-fs-md sm-lh-md fw-700 c-primary mb-2 sm-mb-1 xs-mb-1">
                                    {article?.subTopicSelections
                                      ?.map((e) => e.subTopic.name)
                                      .join(", ")}
                                  </span>
                                  <span className="fs-h-4 lh-h-4 sm-fs-xl sm-lh-h-7 fw-700 c-text-black">
                                    {article.title}
                                  </span>
                                  <span className="fs-lg lh-xl sm-fs-sm sm-lh-md fw-400 c-text-4">
                                    {article.excerpt}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          ))}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col grow-1 justify-start align-start topics-sidebar xs-mt-12">
                  <div className="flex mb-6 w-100 border-b pb-6 sm-pb-3 sm-mb-4">
                    <span className="fs-h-4 lh-h-4 sm-fs-h-6 sm-lh-h-6 fw-700 c-text-2">
                      Trending
                    </span>
                  </div>
                  {latestArticles?.map((article: Article, index: number) => (
                    <Link
                      to={`/article/${article.slug}`}
                      key={article.id}
                      className="flex flex-col w-100"
                    >
                      <div
                        className={`flex flex-col w-100 align-start border-b pb-6 sm-pb-3 xs-mb-4 xs-pb-4
                  ${index < latestArticles?.length - 1 ? "mb-6 sm-mb-3" : ""}`}
                      >
                        <div className="flex flex-row w-100 justify-between mb-6 sm-mb-3">
                          <span className="fs-xl lh-xl sm-fs-md sm-lh-md fw-700 c-primary sm-mr-1">
                            {article?.subTopicSelections
                              ?.map((e) => e.subTopic.name)
                              .join(", ")}
                          </span>
                        </div>
                        <div className="flex flex-col w-100 justify-start align-start align-start">
                          <span className="fs-h-4 lh-h-4 sm-fs-h-6 sm-lh-h-6 xs-fs-h-7 xs-lh-h-7 fw-700 c-text-black mb-2">
                            {article.title}
                          </span>
                          <span className="fs-lg lh-xl sm-fs-sm sm-lh-md fw-400 c-text-4">
                            {article.excerpt}
                          </span>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex justify-center align-center mb-15 mt-15 xs-w-100 xs-px-10">
              <Link
                to="/topics"
                className="btn btn--primary xs-btn--lg xs-w-100"
              >
                see all topics
              </Link>
            </div>
          </>
        )}

        {/*<div className="flex flex-col">
              <div className="flex justify-start align-start col-gap-4 mb-4">
                  <button className="btn btn--lg btn--primary">test</button>
                  <button className="btn btn--primary">test</button>
                  <button className="btn btn--sm btn--primary">test</button>
              </div>

              <div className="flex justify-start align-start col-gap-4 mb-4">
                  <button className="btn btn--lg sm-btn--lg btn--primary">test</button>
                  <button className="btn sm-btn btn--primary">test</button>
                  <button className="btn btn--sm sm-btn--sm btn--primary">test</button>
              </div>

              <div className="flex justify-start align-start col-gap-4 mb-4">
                  <button className="btn btn--lg xs-btn--lg btn--primary">test</button>
                  <button className="btn xs-btn btn--primary">test</button>
                  <button className="btn btn--sm xs-btn--sm btn--primary">test</button>
              </div>

              <div className="flex justify-start align-center col-gap-6 mb-4">
                  <div className="input-wrap">
                      <input type="text" className="input" id="testinput" placeholder="1" />
                      <label className="input-label" htmlFor="testinput">placeholder</label>
                  </div>

                  <div className="input-wrap">
                      <input type="text" className="input" placeholder="Placeholder" />
                  </div>

                  <div className="px-4 py-4 bg-primary">
                      <div className="input-wrap input-wrap--light">
                          <input type="text" className="input" placeholder="Placeholder" />
                      </div>
                  </div>

                  <div className="input-wrap input-wrap--dark">
                      <input type="text" className="input" placeholder="Placeholder" />
                  </div>
              </div>

              <div className="flex justify-start align-center col-gap-6 mb-4">
                  <div className="input-wrap input-wrap--lg">
                      <input type="text" className="input" id="testinput-2" placeholder="1" />
                      <label className="input-label" htmlFor="testinput-2">placeholder</label>
                  </div>

                  <div className="input-wrap input-wrap--lg">
                      <input type="text" className="input" placeholder="Placeholder" />
                  </div>

                  <div className="px-4 py-4 bg-primary">
                      <div className="input-wrap input-wrap--light input-wrap--lg">
                          <input type="text" className="input" placeholder="Placeholder" />
                      </div>
                  </div>

                  <div className="input-wrap input-wrap--dark input-wrap--lg">
                      <input type="text" className="input" placeholder="Placeholder" />
                  </div>
              </div>

              <div className="flex justify-start align-center col-gap-6 mb-4">
                  <div className="input-wrap input-wrap--sm">
                      <input type="text" className="input" id="testinput-3" placeholder="1" />
                      <label className="input-label" htmlFor="testinput-3">placeholder</label>
                  </div>

                  <div className="input-wrap input-wrap--sm">
                      <input type="text" className="input" placeholder="Placeholder" />
                  </div>

                  <div className="px-4 py-4 bg-primary">
                      <div className="input-wrap input-wrap--light input-wrap--sm">
                          <input type="text" className="input" placeholder="Placeholder" />
                      </div>
                  </div>

                  <div className="input-wrap input-wrap--dark input-wrap--sm">
                      <input type="text" className="input" placeholder="Placeholder" />
                  </div>
              </div>

              <div className="flex justify-start align-center col-gap-6 mb-4">
                  <div className="input-wrap input-wrap--lg sm-input-wrap xs-input-wrap--sm">
                      <input type="text" className="input" id="testinput-2" placeholder="1" />
                      <label className="input-label" htmlFor="testinput-2">placeholder</label>
                  </div>

                  <div className="input-wrap input-wrap--lg sm-input-wrap xs-input-wrap--sm">
                      <input type="text" className="input" placeholder="Placeholder" />
                  </div>

                  <div className="px-4 py-4 bg-primary">
                      <div className="input-wrap input-wrap--light input-wrap--lg sm-input-wrap xs-input-wrap--sm">
                          <input type="text" className="input" placeholder="Placeholder" />
                      </div>
                  </div>

                  <div className="input-wrap input-wrap--dark input-wrap--lg sm-input-wrap xs-input-wrap--sm">
                      <input type="text" className="input" placeholder="Placeholder" />
                  </div>
              </div>
          </div>*/}
      </div>
  );
};

export default HomePage;
