import {ErrorMessage, Field, Formik} from 'formik';
import React, {Dispatch, FC, SetStateAction, useContext, useEffect, useState} from 'react';
import * as Yup from 'yup';
import {createUserWithEmailAndPassword, getAuth,} from 'firebase/auth';
import {ErrorCode, getErrorMessageByCode,} from '../../../utils/errorResponses';
import {useAuthMutation} from '../../hooks/useAuth';
import {SocialLogin} from './SocialLogin';
import {useTrackGoogleAnalyticsEvent} from '../../hooks/useTrackGoogleAnalyticsEvent';
import {getProvider} from '../../../api/apiAuth';
import {MixpanelEvent} from '../../enums/mixpanel-event';
import {guestUserTransfer} from '../../../utils/helperUtils';
import {mixpanelSetPeople} from "../../../utils/mixpanel-set-people";
import {mixpanelTrack} from "../../../utils/mixpanel-track";
import AppContext from "../../context/app-context";
import {ClientMe} from "../../types/user";

interface SignupProps {
  handleClose: () => void;
  loginButtonClick: () => void;
  onAfterSubmit: () => void;
}

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
};

interface showAuthInterface{
  setShowAuthPopup: React.Dispatch<SetStateAction<any>> | boolean;
  setMe: Dispatch<SetStateAction<ClientMe | undefined | null>>;
}

const reqMsg = (fieldName = "This field"): string => `${fieldName} is required`;

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required(reqMsg()),
  firstName: Yup.string().matches(/^\s*\S[\s\S]*$/, 'First Name is required.').required(reqMsg()),
  lastName: Yup.string().matches(/^\s*\S[\s\S]*$/, 'Last Name is required.').required(reqMsg()),
  password: Yup.string()
    .required(reqMsg())
    .min(6, "Password should be at least 6 characters"),
});

const Signup: FC<SignupProps> = ({
  handleClose,
  loginButtonClick,
}) => {
  const { signup, isLoadingMutation } = useAuthMutation();
  const [errorMessage, setErrorMessage] = useState("");
  const logGoogleEvent = useTrackGoogleAnalyticsEvent();
  const {setShowAuthPopup}: showAuthInterface = useContext(AppContext);
  const onSubmit = async ({
    email,
    firstName,
    lastName,
    password,
  }: {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
  }) => {
    const firstNameReady = firstName.trim();
    const lastNameReady = lastName.trim();
    const auth = getAuth();
    let userCredential: any;
    try {
      userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
    } catch (error) {
      if ((error as any).code === ErrorCode.EMAIL_EXISTS) {
        getProvider(email).then((data: any) =>
          setErrorMessage(
            getErrorMessageByCode(
              (error as any)?.code,
              (data.provider === "google.com" ||
                data.provider === "facebook.com") &&
                data.provider,
            ),
          ),
        );
      } else {
        logGoogleEvent("sign-up", getErrorMessageByCode((error as any)?.code));
        setErrorMessage(getErrorMessageByCode((error as any)?.code));
      }
    }
    if (!userCredential?.user) {
      return;
    }
    await guestUserTransfer(firstNameReady, lastNameReady);
    signup(
      {
        payload: {isAdmin: false, firstName: firstNameReady, lastName: lastNameReady},
      },
      {
        onSuccess: async ()  => {
          mixpanelTrack(MixpanelEvent.account_created);
          mixpanelSetPeople(MixpanelEvent.register_for_account, {name: userCredential.user.firstName , email: userCredential.user.email, id: userCredential.user.uid});
          window?.postMessage(
              {data: { LoggedIn: true }, type: 'loggedInEventToGame'},
              '*');
          setShowAuthPopup(false);
        },
        onError: (error) => {
          logGoogleEvent(
            "sign-up",
            getErrorMessageByCode((error as any)?.code),
          );
          auth.signOut();
        },
      },
    );
  };

  useEffect(() => {
    mixpanelTrack(MixpanelEvent.signup_opened);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <form className="flex flex-col w-100" onSubmit={handleSubmit}>
          <div className="flex flex-col w-100 justify-center">
            <div className="flex justify-between mb-12">
              <h2 className="fs-h-7 lh-h-7 fw-700 c-primary">Create Account</h2>
              <button
                className="btn-reset c-text-1 text-left"
                onClick={handleClose}
              >
                <i className="flipicon  flipicon-close"></i>
              </button>
            </div>
            <div className="input-wrap input-wrap--sm w-100 mb-3">
              <div className="input-error-text">
                <ErrorMessage name="email" component="span" />
              </div>
              <Field
                type="text"
                className="input"
                id="email"
                placeholder="1"
                name="email"
              />
              <label className="input-label" htmlFor="email">
                Email<span className="c-text-3">*</span>
              </label>
            </div>
            <div className="input-wrap input-wrap--sm w-100 mb-3">
              <div className="input-error-text">
                <ErrorMessage name="firstName" component="span" />
              </div>
              <Field
                type="text"
                className="input"
                id="fname"
                placeholder="1"
                name="firstName"
              />
              <label className="input-label" htmlFor="fname">
                First Name<span className="c-text-3">*</span>
              </label>
            </div>
            <div className="input-wrap input-wrap--sm w-100 mb-3">
              <div className="input-error-text">
                <ErrorMessage name="lastName" component="span" />
              </div>
              <Field
                type="text"
                className="input"
                id="lname"
                placeholder="1"
                name="lastName"
              />
              <label className="input-label" htmlFor="lname">
                Last Name<span className="c-text-3">*</span>
              </label>
            </div>
            <div className="input-wrap input-wrap--sm w-100 mb-3">
              <div className="input-error-text">
                <ErrorMessage name="password" component="span" />
              </div>
              <Field
                type="password"
                className="input"
                id="password"
                placeholder="1"
                name="password"
              />
              <label className="input-label" htmlFor="password">
                Password<span className="c-text-3">*</span>
              </label>
            </div>
            <div className="ml-auto mb-4 xs-w-100">
              <button
                className="btn btn--sm xs-btn--full xs-w-100 btn--primary"
                type="submit"
                disabled={isLoadingMutation}
              >
                sign up
              </button>
            </div>
            <div className="input-error-text input-error-text-50  text-center">
              <span>{errorMessage}</span>
            </div>
          </div>
          <div className="flex flex-col w-100 justify-center mt-auto xs-mt-6">
            <div className="flex align-center justify-center mb-6">
              <span className="fs-md xs-fs-sm lh-md xs-lh-sm fw-500 c-text-4">
                Or Sign Up with..
              </span>
            </div>
            <SocialLogin onSuccess={handleClose}></SocialLogin>
            <div className="flex align-start text-start">
              <span className="fs-md xs-fs-sm lh-md xs-lh-sm fw-500 c-text-4">
                Already have an account?{" "}
                <a
                  className="fs-md xs-fs-sm lh-md xs-lh-sm fw-700 c-primary cursor-pointer"
                  onClick={loginButtonClick}
                >
                  Log In
                </a>{" "}
              </span>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default Signup;
