import {FC} from 'react';
import {SocialShare} from '../../../common/components/SocialShare';
import {Link} from 'react-router-dom';
import {Article} from '../../../common/types/articles';

interface ScoreProp {
  userId?: number;
  imageData: string;
  roundCount: number;
  data: {
    score: number;
    rounds: number;
    articles: any[];
    gameId: number;
    pupStarCount: number | undefined;
    showMode: boolean;
  };
}

const Score: FC<ScoreProp> = ({userId, data, imageData, roundCount}) => {

  const getScoreMessage = (): string => {
    let message = '';
    const propScore = data.score / (data?.rounds || roundCount);
    const score = propScore * 5;

    if (score >= 500) {
      message = 'You beat 99% of other players';
    } else if (score >= 475 && score <= 499) {
      const percentage = Math.floor(Math.random() * (98 - 90 + 1)) + 90;
      message = `You beat ${percentage}% of other players`;
    } else if (score >= 450 && score <= 474) {
      const percentage = Math.floor(Math.random() * (89 - 80 + 1)) + 80;
      message = `You beat ${percentage}% of other players`;
    } else if (score >= 425 && score <= 449) {
      const percentage = Math.floor(Math.random() * (79 - 65 + 1)) + 65;
      message = `You beat ${percentage}% of other players`;
    } else if (score >= 400 && score <= 424) {
      const percentage = Math.floor(Math.random() * (64 - 50 + 1)) + 50;
      message = `You beat ${percentage}% of other players`;
    }

    return message;
  };

  return (
    <div className="container container--sm xs-container--sm mt-5 xs-px-6">
      <div className="flex flex-col w-100 justify-center align-center">
        <div
          className="score-wrapper flex flex-col w-100 bg-white overflow border-primary br--lg justify-center align-center px-12 py-12 mb-12 xs-px-6 xs-py-6 text-center">
          <div className="flex flex-col w-100 align-center tex-center mb-6 xs-mb-4">
              <span className="fs-h-5 lh-h-5 xs-fs-h-7 xs-lh-h-7 fw-700 c-primary mb-2">
                Today's Score {data?.score}
              </span>
            <span className="fs-lg lh-lg fw-700 c-text-1">
                {getScoreMessage()}
              </span>
          </div>
          <div className="flex flex-col w-100">
            {!!imageData && <img src={imageData} alt="result"/>}
          </div>
          <div onClick={(event) => data.showMode && event.preventDefault()}>
            <SocialShare
              type={"game"}
              userId={userId}
              imageData={imageData}
              score={data?.score}
            ></SocialShare>
          </div>
        </div>

        <div className="flex flex-col w-100 justify-center align-center text-cennter">
          <div className="flex flex-col w-100 submit-media submit-media--sm mb-12 xs-mb-6">
            <img
              className="img img--full img--contain"
              src="/images/dog.png"
              alt=""
            />
          </div>
          <div className="flex flex-col justify-center align-center xs-w-100">
            <Link
              to="/submit-dog-step-1"
              state={{showAuthPopup: true}}
              className="btn btn--primary w-100 mb-6"
              onClick={(event) => data.showMode && event.preventDefault()}
            >
              submit my dog
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-100">
        <div className="flex flex-col justify-start align-start">
            <span className="fs-h-3 lh-h-3 sm-fs-h-6 sm-lh-h-6 fw-700 c-text-black">
              Go Deeper on Today’s Topics
            </span>
          <span className="fs-lg lh-xl sm-fs-sm sm-lh-sm fw-400 c-text-4">
              Learn more about themes from today's game.
            </span>
        </div>
        <div className="flex flex-col w-100 justify-center align-center pt-6 xs-pt-0">
          {data?.articles?.map((article: Article) => (
            <Link
              to={`/article/${article.slug}`}
              onClick={(event) => data.showMode && event.preventDefault()}
              className="flex sm-flex-col xs-flex-col sm-flex-row justify-start w-100 border-b--primary py-6 xs-py-4"
              key={article.id}
            >
              <div className="flex topics-media--lg mr-12 sm-mr-8 xs-mr-0">
                <img
                  className="img img--fill img--contain"
                  src={article.thumbnailUrl}
                  alt=""
                />
              </div>
              <div className="flex flex-col align-start justify-start xs-mt-2 ">
                  <span className="fs-xl lh-xl sm-fs-md sm-lh-md fw-700 c-primary mb-2 sm-mb-1 xs-mb-1">
                    {article?.subTopicSelections
                      ?.map((e) => e.subTopic.name)
                      .join(", ")}
                  </span>
                <span className="fs-h-4 lh-h-4 sm-fs-xl sm-lh-h-7 fw-700 c-text-black mb-2 sm-mb-1 xs-mb-1">
                    {article.title}
                  </span>
                <span className="fs-lg lh-xl sm-fs-sm sm-lh-md fw-700 c-text-black ">
                    By{" "}
                  <span className="fs-lg lh-xl sm-fs-sm sm-lh-md fw-700 c-primary">
                      {article.articleAuthor?.displayName}{" "}
                    </span>
                  </span>
              </div>
            </Link>
          ))}
        </div>
        <div className="flex justify-center align-center mt-15 mb-12 xs-mt-6  xs-w-100 xs-px-10">
          {/*    <button className="btn btn--primary xs-btn--lg xs-w-100">*/}
          {/*      more stories*/}
          {/*    </button>*/}
        </div>
      </div>
    </div>
  );
};

export default Score;
