import {getRequest, postRequest, putRequest} from './request';
import {FirstRound, GameConfig, IDailyProgress} from '../common/types/game';
import {AlbumsResponse, UserPupStarCards} from '../common/types/albums';
import {AxiosRequestConfig} from 'axios';

export const getCurrentGame = async (config?: AxiosRequestConfig): Promise<GameConfig> => {
  return await getRequest<GameConfig>(`games/ongoing-game`, undefined, config).then((res) => res.data);
};

export const getAlbums = async (config?: AxiosRequestConfig): Promise<AlbumsResponse> => {
  return await getRequest<any>(`albums`, undefined, config).then((res) => res.data);
};

export const getGameConfig = async (config?: AxiosRequestConfig): Promise<GameConfig> => {
  return await getRequest<GameConfig>(`games/game-config`,undefined,  config).then(
    (res) => res.data,
  );
};

export const startGame = async (gameId: number, config?: AxiosRequestConfig): Promise<any> => {
  return await postRequest<any>(`user-games/games/${gameId}`, null, config).then(
    (res) => res.data,
  );
};

export const setAnswer = async (gameId: number, answerId: number, secondAnswerId?: string,  config?: AxiosRequestConfig): Promise<any> => {
  return await putRequest<any>(`user-games/games/${gameId}/answers/${answerId}`, {secondAnswerId}, config).then(
      (res) => res.data,
  );
};

export const setHint = async (gameId: number, config?: AxiosRequestConfig): Promise<any> => {
  return await putRequest<any>(`user-games/games/${gameId}/hint`, {}, config).then(
      (res) => res.data,
  );
};

export const setPupStarCardsSeen = async (cards: UserPupStarCards[]): Promise<any> => {
  return Promise.all(cards.map((card) => setPupStarCardSeen(card.pupStarCardId)))
    .catch((err) => console.error(err));
};

export const setPupStarCardSeen = async (id: number): Promise<any> => {
  return await putRequest<any>(`user-pup-star-cards/${id}/seen`, {}).then(
    (res) => res.data,
  );
};

export const getFirstRound = async (config?: AxiosRequestConfig): Promise<FirstRound> => {
  return await getRequest<any>(`games/daily/first-round`, undefined, config).then((res) => res.data);
};

export const getDailyProgress = async (config?: AxiosRequestConfig): Promise<IDailyProgress> => {
  return await getRequest<IDailyProgress>(`user-games/daily-progress`, undefined, config).then((res) => res.data);
};

export const setAllDone = async (config?: AxiosRequestConfig): Promise<any> => {
  return await putRequest<any>(`user-games/done`, {}, config).then(
      (res) => res.data,
  );
};
