import React, {FC, useContext, useEffect, useMemo, useState} from 'react';
import SubmitDogProgressBar from '../../../common/components/SubmitDogProgressBar';
import {useCreateUpdateUserSubmission, useUserDraftedSubmission,} from '../../../common/hooks/useUserSubmission';
import {useNavigate} from 'react-router-dom';
import {UserSubmissionStepFive, UserSubmissionStepThree,} from '../../../common/types/user-submissions';
import axios from 'axios';
import {GUEST_ID_API, GUEST_TOKEN_KEY, guestLoginApi, signedUrlApi} from '../../../api/apiAuth';
import AppContext from '../../../common/context/app-context';
import styled from '@emotion/styled';

const dogPhotoList: string[] = [
  'Favorite activities',
  'Interesting expressions',
  'Interactions with other dogs or people',
  'Caught in the act (naughty behavior)'
];

interface UploadProps {
  type: 'Img' | 'Video';
  currentStep: number;
}

const defaultSubmissionStepThree: UserSubmissionStepThree = {
  dogImgUrl1: null,
  dogImgUrl2: null,
  step: 3,
};

const defaultSubmissionStepFive: UserSubmissionStepFive = {
  dogVideoUrl1: null,
  dogVideoUrl2: null,
  step: 5,
};

const Loader = styled('span')`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  animation: rotate 1s linear infinite;

  ::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0;
    border-radius: 50%;
    border: 5px solid #FFF;
    animation: prixClipFix 2s linear infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg)
    }
  }

  @keyframes prixClipFix {
    0% {
      clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }
    25% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }
    50% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }
    75% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
    }
    100% {
      clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
    }
  }
`;

const ImageVideoUpload: FC<UploadProps> = ({type, currentStep}) => {
  const {me} = useContext(AppContext);
  const [userId, setUserId] = useState<number | null | undefined | string>(me?.id);

  const config =
    me
      ? {}
      : {
        baseURL: process.env.REACT_APP_SERVER_API?.replace(
          'client',
          'guest',
        ),
      }

  const {data: userSubmissionDrafted} = useUserDraftedSubmission(userId, config);

  useEffect(() => {
    if (me !== undefined) {
      setUserId(!me ? (+localStorage.getItem(GUEST_ID_API)!) : me.id);
    }
  }, [setUserId, me]);

  const {submissionCreateUpdate} = useCreateUpdateUserSubmission();
  const navigate = useNavigate();
  const [uploadedFileData, setUploadedFileData] = useState<any[]>([null, null]);
  const [formData, setFormData] = useState<
    UserSubmissionStepThree | UserSubmissionStepFive
  >(type === 'Img' ? defaultSubmissionStepThree : defaultSubmissionStepFive);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [recaptchaVerified, setRecaptchaVerified] = useState(true);

  const checkIsValid = useMemo(() => {
    const formValues = Object.values(formData);
    if (formData.step === 5) {
      return true;
    }
    return (
      uploadedFileData.every((img) => !!img) ||
      (formValues.every((fv) => !!fv) &&
        uploadedFileData.some((file) => !!file))
    );
  }, [uploadedFileData, formData]);

  useEffect(() => {
    const data =
      type === "Img"
        ? userSubmissionDrafted?.data?.steps?.[2]
        : userSubmissionDrafted?.data?.steps?.[4];
    if (!data) {
      return;
    }
    setFormData((prevState) => ({ ...prevState, ...data }));
  }, [userSubmissionDrafted]);

  const saveImages = (data: any) => {
    submissionCreateUpdate(
      {
        payload: data,
        type: "update",
        config: config,
        id: userSubmissionDrafted?.id || 0,
      },
      {
        onSuccess: () =>
          navigate(type === 'Img' ? '/submit-dog-step-4' : '/submit-dog-final'),
        onError: async (error) => {
          if ((error as any)?.response?.data?.errorCode === "NO_UID") {
            const guestToken = await guestLoginApi();
            localStorage.setItem(GUEST_ID_API, guestToken.id);
            localStorage.setItem(GUEST_TOKEN_KEY, guestToken[GUEST_TOKEN_KEY]);
            navigate("/submit-dog-step-1");
          }
          setHasError(true)
        }
      },
    );
  };

  const handleChange = (ev: any, index: number): void => {
    setUploadedFileData((prevState) => {
      prevState[index] = ev?.target?.files[0];
      return [...prevState];
    });
  };

  const uploadToS3 = async (file: File, index: number) => {
    const s3Url = await signedUrlApi({
      contentType: file.type,
      fileName: file.name.slice(0, file.name.lastIndexOf('.')),
      config: config
    }).catch(() => setHasError(true));
    if (!s3Url) return;
    const image_url = s3Url.split('?')[0];
    await axios.put(s3Url, file, {
      headers: {
        'x-amz-acl': 'public-read',
        contentType: file.type,
      },
    }).catch(() => setHasError(true));
    return {
      [`dog${type}Url${index + 1}`]: image_url,
    };
  };

  const handleSubmit = async (): Promise<void> => {
    setHasError(false);
    setIsLoading(true);
    let data: UserSubmissionStepThree | UserSubmissionStepFive = formData;
    if (uploadedFileData[0]) {
      data = {...data, ...(await uploadToS3(uploadedFileData[0], 0))};
    }
    if (uploadedFileData[1]) {
      data = {...data, ...(await uploadToS3(uploadedFileData[1], 1))};
    }
    Object.keys(data).forEach(
      (k) => (data as any)[k] == null && delete (data as any)[k],
    );
    setIsLoading(false);
    setFormData(data);
    saveImages(data);
  };

  const onRecaptchaChange = (value: any) => {
    setRecaptchaVerified(!!value);
  };

  return (
    <div className="container xs-container--sm xs-px-6 xs-py-6">
      <div className="flex flex-col mb-6">
        <div className="flex w-100 justify-between align-center sm-flex-col-reverse mb-6 xs-mb-2">
          <h2 className="fs-h-3 lh-h-3 sm-fs-h-4 sm-lh-h-4 xs-fs-h-6 xs-lh-h-6 fw-700 c-text-black grow-1 mr-2 sm-mr-0">
            Submit My Dog
          </h2>
          <SubmitDogProgressBar
            currentStep={currentStep}
            stepsCount={userSubmissionDrafted?.data?.steps?.length || 0}
          />
        </div>
        <p className="fs-h-7 lh-h-7 fw-700 c-primary xs-fs-sm xs-lh-sm sm-fs-xl sm-lh-xl ">
          Time to share great {type === "Img" ? "PHOTOS" : "VIDEOS"} of your dog!
        </p>
      </div>
      <div className="flex flex-col mb-12 xs-mb-6">
          <span className="fs-h-7 lh-h-7 fw-700 c-text-black xs-fs-sm xs-lh-sm sm-fs-xl sm-lh-xl mb-6 sm-mb-5 xs-mb-4">
            We're looking for:
          </span>
        <ul className="submit-list">
          {dogPhotoList.map((list: string) => {
            return (
              <li className="list-item--submit fs-h-7 lh-h-7 fw-700 xs-fs-sm xs-lh-sm sm-fs-xl sm-lh-xl c-primary">
                {list}
              </li>
            )
          })}
        </ul>
      </div>
      <div
        className="flex flex-col justify-start align-start w-100 border br bg-white px-5 py-5 xs-px-4 xs-py-4  xs-flex-col mb-12 xs-mb-0">
        <div className="flex w-100 xs-flex-col">
          <div className="w-50 xs-w-100 flex flex-col justify-start border-bottom mx-3 xs-mx-0 xs-mt-0">
              <span className="fs-xl lh-xl xs-fs-sm xs-lh-sm fw-700 c-text-black mb-8 xs-mb-4">
                Your Dog - {type === "Img" ? "PHOTO" : "VIDEO"} 1
                {formData.step === 3 ? "*" : ""}
              </span>

            {uploadedFileData[0] ||
            ("dogImgUrl1" in formData && formData?.dogImgUrl1) ||
            ("dogVideoUrl1" in formData && formData?.dogVideoUrl1) ? (
              <div className="flex submit-media submit-media--lg br--sm overflow">
                {type === "Img" ? (
                  <img
                    className="img img--full img--contain"
                    src={
                      uploadedFileData[0]
                        ? URL.createObjectURL(uploadedFileData[0])
                        : ("dogImgUrl1" in formData && formData.dogImgUrl1) ||
                        ""
                    }
                    alt=""
                  />
                ) : (
                  <video>
                    <source
                      src={
                        uploadedFileData[0]
                          ? URL.createObjectURL(uploadedFileData[0])
                          : ("dogVideoUrl1" in formData &&
                            formData.dogVideoUrl1) ||
                          ""
                      }
                    ></source>
                  </video>
                )}
              </div>
            ) : (
              <div className="flex justify-center align-center submit-media submit-media--lg br--sm overflow">
                <span className="flipicon flipicon--xxl flipicon-image c-primary-light-2"></span>
              </div>
            )}

            {type === "Img" ? (
              !uploadedFileData[0] &&
              !("dogImgUrl1" in formData && formData.dogImgUrl1) ? (
                <span className="mt-2 fs-xs-lh-xs c-text-3">
                    Image is Required
                  </span>
              ) : (
                <></>
              )
            ) : (
              <span className="mt-2 fs-xs-lh-xs c-text-black">
                  Keep it to 30 secs or less.
                </span>
            )}
            <div className="flex justify-start align-center mt-2 mb-8 xs-mt-4 xs-mb-6  xs-w-100">
              <label
                htmlFor="fileinput1"
                className="btn btn--secondary xs-btn--lg xs-w-100"
              >
                select {type === "Img" ? "photo" : "video"}
              </label>
              <input
                type="file"
                accept={
                  type === "Img"
                    ? "image/png, image/gif, image/jpeg"
                    : "video/mp4,video/x-m4v,video/*"
                }
                id="fileinput1"
                onChange={(event) => handleChange(event, 0)}
                hidden
              />
            </div>
          </div>
          <div className="w-50 xs-w-100 flex flex-col justify-start border-bottom mx-3 xs-mx-0 xs-mt-6">
              <span className="fs-xl lh-xl xs-fs-sm xs-lh-sm fw-700 c-text-black mb-8 xs-mb-4">
                Your Dog - {type === "Img" ? "PHOTO" : "VIDEO"} 2
                {formData.step === 3 ? "*" : ""}
              </span>

            {uploadedFileData[1] ||
            ("dogImgUrl2" in formData && formData?.dogImgUrl2) ||
            ("dogVideoUrl2" in formData && formData?.dogVideoUrl2) ? (
              <div className="flex submit-media submit-media--lg br--sm overflow">
                {type === "Img" ? (
                  <img
                    className="img img--full img--contain"
                    src={
                      uploadedFileData[1]
                        ? URL.createObjectURL(uploadedFileData[1])
                        : ("dogImgUrl2" in formData && formData.dogImgUrl2) ||
                        ""
                    }
                    alt=""
                  />
                ) : (
                  <video>
                    <source
                      src={
                        uploadedFileData[1]
                          ? URL.createObjectURL(uploadedFileData[1])
                          : ("dogVideoUrl2" in formData &&
                            formData.dogVideoUrl2) ||
                          ""
                      }
                    ></source>
                  </video>
                )}
              </div>
            ) : (
              <div className="flex justify-center align-center submit-media submit-media--lg br--sm overflow">
                <span className="flipicon flipicon--xxl flipicon-image c-primary-light-2"></span>
              </div>
            )}

            {type === "Img" ? (
              !uploadedFileData[1] &&
              !("dogImgUrl2" in formData && formData.dogImgUrl2) ? (
                <span className="mt-2 fs-xs-lh-xs c-text-3">
                    Image is Required
                  </span>
              ) : (
                <></>
              )
            ) : (
              <span className="mt-2 fs-xs-lh-xs c-text-black">
                  Keep it to 30 secs or less.
                </span>
            )}
            <div className="flex justify-start align-center mt-2 mb-8 xs-mt-4 xs-mb-6  xs-w-100">
              <label
                htmlFor="fileinput2"
                className="btn btn--secondary xs-btn--lg xs-w-100"
              >
                select {type === "Img" ? "photo" : "video"}
              </label>
              <input
                type="file"
                accept={
                  type === "Img"
                    ? "image/png, image/gif, image/jpeg"
                    : "video/mp4,video/x-m4v,video/*"
                }
                id="fileinput2"
                onChange={(event) => handleChange(event, 1)}
                hidden
              />
            </div>
          </div>
        </div>
        {/*<div className="flex flex-col mt-12 xs-mt-6 justify-start xs-justify-center xs-align-center pl-3 xs-mx-auto">*/}
        {/*  <ReCAPTCHA*/}
        {/*    sitekey={`${process.env.REACT_APP_RECAPTCHA_KEY}`}*/}
        {/*    onChange={onRecaptchaChange}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="flex justify-start align-center mt-12 xs-mx-auto mb-3 xs-mt-6 xs-mb-0  xs-w-200"
             style={{gap: '20px'}}>
          <button
            className={`btn btn--secondary xs-btn--lg xs-w-100 ${
              !checkIsValid || isLoading ? "btn--disabled" : ""
            }`}
            onClick={handleSubmit}
          >
            submit and continue &nbsp; {isLoading && <Loader/>}
          </button>
          {hasError && <div className="c-text-3">There was an error uploading the video. Please reach out to &nbsp;
            <a href="https://flipthebreed.com" className="c-text-3" target="_blank">help@flippedondogs.com</a>
            &nbsp; for assistance.
          </div>}
        </div>
      </div>
    </div>
  );
};

export default ImageVideoUpload;
